import { ComponentPropsWithoutRef, forwardRef, MouseEvent, Ref } from 'react';

import { ExternalLink } from '@ui-uikit/lib/components/common/ExternalLink';

import NextLink from '../NextLink';
import { useNavLayout } from './Header';

export interface NavLayoutLinkProps extends ComponentPropsWithoutRef<'a'> {
  external?: boolean;
  isAuthRequired?: boolean;
}

const NavLayoutLink = (
  { href = '', isAuthRequired, external, onClick, ...props }: NavLayoutLinkProps,
  ref: Ref<'a'>,
) => {
  const Link = external && !href.startsWith('/') ? ExternalLink : NextLink;
  const { user, onLogin } = useNavLayout();

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (isAuthRequired && !user) {
      onLogin?.();
      event.preventDefault();

      return;
    }

    onClick?.(event);
  };

  return (
    <Link
      href={href}
      onClick={handleClick}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      ref={ref}
      {...props}
    />
  );
};

export default forwardRef(NavLayoutLink);
