import { usePathname } from 'next/navigation';

import classNames from 'classnames';

import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { ArrowDown } from '@ui/uikit/components/icons/mono';
import { cn } from '@ui/uikit/utils';

import NavLayoutLink from './NavLayoutLink';
import { PrimaryNavItem } from './types';

export interface PrimaryNavDesktopProps {
  items?: PrimaryNavItem[];
  className?: string;
  isConnected?: boolean;
  onLogin?: () => void;
}

export const PrimaryNavDesktop = ({
  items,
  onLogin,
  className,
  isConnected,
}: PrimaryNavDesktopProps) => {
  const pathname = usePathname();

  return (
    <NavigationMenu.Root className={cn('max-3xl-msq:hidden z-10', className)}>
      <NavigationMenu.List className="flex">
        {items?.map(
          ({
            id,
            label,
            target,
            isHidden,
            href,
            external,
            isOnlyAuth,
            isAuthRequired,
            children: navChildren,
          }) => {
            if (isHidden) {
              return null;
            }

            const isDisabled = false;

            const isActive = navChildren
              ? !!navChildren.find((el) => el.href === pathname)
              : href === pathname;

            const rootLinkStyleClassName = classNames(
              'msq-btn msq-btn-lg px-3 hover:bg-transparent msq-btn-ghost',
              {
                'text-base-text-tertiary hover:text-base-text-pink': !isDisabled && !isActive,
                'text-base-text-primary cursor-default': !isDisabled && isActive,
                'msq-btn-ghost-disabled': isDisabled,
              },
            );
            const isNavRootProtected = isOnlyAuth && !isConnected;

            if (navChildren) {
              return (
                <NavigationMenu.Item key={id}>
                  <NavigationMenu.Trigger
                    key={id}
                    onClick={(event) => {
                      if (isNavRootProtected) {
                        event.preventDefault();
                        onLogin?.();
                      }
                    }}
                    className={cn(rootLinkStyleClassName, 'group')}
                  >
                    {label}
                    <ArrowDown className="msq-btn-icon-child ml-1 group-data-[state=open]:rotate-180 transition-transform" />
                  </NavigationMenu.Trigger>

                  <NavigationMenu.Content
                    className={cn(
                      'msq-sub-menu absolute top-full min-w-[13.5rem] duration-150',
                      'data-[state=open]:animate-show-menu',
                      'data-[state=closed]:animate-hide-menu',
                      // 'data-[motion=from-start]:animate-slide-in-from-left data-[motion=from-end]:animate-slide-in-from-right data-[motion=to-start]:animate-slide-out-to-left data-[motion=to-end]:animate-slide-out-to-right',
                    )}
                  >
                    <ul>
                      {navChildren.map((navChild) => {
                        if (navChild.isHidden) {
                          return null;
                        }

                        const isNavChildDisabled = false;
                        const isNavChildActive = !isDisabled && pathname === navChild.href;
                        const isNavChildProtected = navChild.isOnlyAuth && !isConnected;

                        return (
                          <li key={navChild.id}>
                            <NavigationMenu.Link asChild>
                              <NavLayoutLink
                                onClick={(event) => {
                                  if (isNavChildProtected) {
                                    event.preventDefault();
                                    onLogin?.();
                                  }
                                }}
                                isAuthRequired={navChild.isAuthRequired}
                                external={navChild.external}
                                href={navChild.href}
                                className={cn('msq-sub-menu-link msq-sub-menu-link-desktop', {
                                  'msq-sub-menu-link-active': isNavChildActive,
                                  'msq-sub-menu-link-disabled': isNavChildDisabled,
                                })}
                              >
                                {navChild.label}
                              </NavLayoutLink>
                            </NavigationMenu.Link>
                          </li>
                        );
                      })}
                    </ul>
                  </NavigationMenu.Content>
                </NavigationMenu.Item>
              );
            }

            return (
              <NavLayoutLink
                key={id}
                target={target}
                href={href}
                external={external}
                isAuthRequired={isOnlyAuth || isAuthRequired}
                onClick={(event) => {
                  if (isNavRootProtected) {
                    event.preventDefault();
                    onLogin?.();
                  }
                }}
                className={classNames(rootLinkStyleClassName)}
              >
                {label}
              </NavLayoutLink>
            );
          },
        )}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
};
