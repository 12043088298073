import { usePathname } from 'next/navigation';

import React, { ReactNode, useEffect, useState } from 'react';
import { useTransition } from 'react-transition-state';

import classNames from 'classnames';

import * as Dialog from '@radix-ui/react-dialog';
import { ArrowLeftLg, ArrowRight, Menu } from '@ui/uikit/components/icons/mono';
import { CrossLg } from '@ui/uikit/components/icons/mono/CrossLg';
import { cn } from '@ui/uikit/utils';

import NavLayoutLink from './NavLayoutLink';
import { PrimaryNavItem } from './types';

export interface PrimaryNavMobileMenuProps extends Dialog.DialogProps {
  items?: PrimaryNavItem[];
  className?: string;
  isConnected?: boolean;
  title?: ReactNode;
  onLogin?: () => void;
}

export const PrimaryNavMobileMenu = ({
  items,
  className,
  title = 'Magic Square',
  isConnected,
  onLogin,
  ...props
}: PrimaryNavMobileMenuProps) => {
  const pathname = usePathname();
  const [activeSubMenuId, setActiveSubMenuId] = useState<string>();

  const transitionProps = {
    timeout: 300,
    mountOnEnter: true,
    unmountOnExit: true,
    preEnter: true,
  };

  const [primaryNavTransition, togglePrimaryNavTransition] = useTransition({
    ...transitionProps,
    initialEntered: true,
  });
  const [secondaryNavTransition, toggleSecondaryNavTransition] = useTransition(transitionProps);

  const toggle = () => {
    togglePrimaryNavTransition();
    toggleSecondaryNavTransition();
  };

  const activePrimaryMenuItem = items?.find(({ id }) => id === activeSubMenuId);

  const [isPrimaryNavMobileMenuOpen, setIsPrimaryNavMobileMenuOpen] = useState(false);

  useEffect(() => {
    setActiveSubMenuId(undefined);
    setIsPrimaryNavMobileMenuOpen(false);
  }, [pathname]);

  const navListClassName = 'flex flex-col gap-1 p-2 overflow-auto';

  const primaryNavAnimationClassName = classNames(
    'transition-all duration-300 left-0 top-0 w-full ease-out',
    {
      absolute:
        primaryNavTransition.status === 'entering' || primaryNavTransition.status === 'exiting',
      'opacity-0 -translate-x-2':
        primaryNavTransition.status === 'preEnter' || primaryNavTransition.status === 'exiting',
    },
  );
  const secondaryNavAnimationClassName = classNames(
    'transition-all duration-300 left-0 top-0 w-full ease-out',
    {
      absolute:
        secondaryNavTransition.status === 'entering' || secondaryNavTransition.status === 'exiting',
      'opacity-0 translate-x-2':
        secondaryNavTransition.status === 'preEnter' || secondaryNavTransition.status === 'exiting',
    },
  );

  const getLinkStyleClassName = ({
    isActive,
    isDisabled,
  }: {
    isDisabled: boolean;
    isActive: boolean;
  }) =>
    cn('msq-sub-menu-link msq-sub-menu-link-mobile w-full', {
      'msq-sub-menu-link-active': !isDisabled && isActive,
      'msq-sub-menu-link-disabled': isDisabled,
    });

  return (
    <>
      <button
        onClick={() => setIsPrimaryNavMobileMenuOpen(true)}
        className={cn(
          'msq-btn msq-btn-icon-md msq-btn-outline size-[2.25rem] !rounded-full md-msq:msq-btn-icon-lg',
          className,
        )}
      >
        <Menu className="msq-btn-icon-child-md" />
      </button>

      <Dialog.Root
        open={isPrimaryNavMobileMenuOpen}
        onOpenChange={setIsPrimaryNavMobileMenuOpen}
        {...props}
      >
        <Dialog.Portal>
          <Dialog.Overlay
            forceMount
            className="msq-dialog msq-dialog-mobile-menu-m msq-dialog-animate"
          >
            <Dialog.Content
              forceMount
              aria-describedby={undefined}
              className={cn(
                'msq-dialog-content sm-msq:msq-dialog-content-sm sm-msq:msq-dialog-content-left',
              )}
            >
              <div className="msq-dialog-header overflow-hidden">
                <div className="w-full relative">
                  {primaryNavTransition.isMounted && (
                    <div className={cn(primaryNavAnimationClassName, 'flex')}>
                      <Dialog.Title className="msq-dialog-title">{title}</Dialog.Title>
                    </div>
                  )}

                  {secondaryNavTransition.isMounted && (
                    <div className={cn(secondaryNavAnimationClassName, 'flex')}>
                      <button
                        onClick={() => {
                          toggle();
                        }}
                        className="msq-btn msq-btn-ghost msq-btn-icon-md msq-dialog-icon-button"
                      >
                        <ArrowLeftLg className="msq-btn-icon-child" />
                      </button>

                      <Dialog.Title className="msq-dialog-title w-full">
                        {activePrimaryMenuItem?.label}
                      </Dialog.Title>
                    </div>
                  )}
                </div>

                <Dialog.Close asChild>
                  <button className="msq-btn msq-btn-ghost msq-btn-icon-md msq-dialog-icon-button">
                    <CrossLg className="msq-btn msq-btn-icon-child" />
                  </button>
                </Dialog.Close>
              </div>

              <div className="msq-dialog-body p-0 flex flex-col relative overflow-x-hidden">
                {primaryNavTransition.isMounted && (
                  <div
                    className={classNames(
                      primaryNavAnimationClassName,
                      'overflow-hidden flex flex-col',
                    )}
                  >
                    <ul className={cn(navListClassName)}>
                      {items?.map((item) => {
                        if (item.isHidden) {
                          return null;
                        }

                        const isProtected = item.isOnlyAuth && !isConnected;
                        const isDisabled = false;
                        const isActive = item.children
                          ? !!item.children.find((el) => el.href === pathname)
                          : item.href === pathname;

                        return (
                          <li key={item.id}>
                            {item.children && (
                              <button
                                onClick={() => {
                                  if (isProtected) {
                                    onLogin?.();
                                    setIsPrimaryNavMobileMenuOpen(false);
                                  } else {
                                    setActiveSubMenuId(item.id);
                                    toggle();
                                  }
                                }}
                                className={getLinkStyleClassName({ isDisabled, isActive })}
                              >
                                {item.label}
                                <ArrowRight className="ml-auto size-4" />
                              </button>
                            )}
                            {!item.children && (
                              <NavLayoutLink
                                href={item.href}
                                onClick={(event) => {
                                  if (isProtected) {
                                    onLogin?.();
                                    event.preventDefault();
                                  }

                                  setIsPrimaryNavMobileMenuOpen(false);
                                }}
                                className={getLinkStyleClassName({ isDisabled, isActive })}
                              >
                                {item.label}
                              </NavLayoutLink>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {secondaryNavTransition.isMounted && (
                  <div className={classNames(secondaryNavAnimationClassName)}>
                    {!!activePrimaryMenuItem?.children?.length && (
                      <ul className={navListClassName}>
                        {activePrimaryMenuItem.children?.map(
                          ({ id, label, isOnlyAuth, href, isHidden }) => {
                            if (isHidden) {
                              return null;
                            }

                            const isProtected = isOnlyAuth && !isConnected;
                            const isDisabled = false;
                            const isActive = href === pathname;

                            return (
                              <li key={id}>
                                <NavLayoutLink
                                  onClick={(event) => {
                                    if (isProtected) {
                                      onLogin?.();
                                      event.preventDefault();
                                    }

                                    setIsPrimaryNavMobileMenuOpen(false);
                                  }}
                                  href={href}
                                  className={getLinkStyleClassName({ isDisabled, isActive })}
                                >
                                  {label}
                                </NavLayoutLink>
                              </li>
                            );
                          },
                        )}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </Dialog.Content>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
    </>
  );
};
